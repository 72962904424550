import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { Menu, Submenu, MenuItem, MenuItemGroup,Divider } from "element-ui";

Vue.config.productionTip = false;

Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);
Vue.use(Divider);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
