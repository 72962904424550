<template>
    <div class="home">
        <div class="nav">
            <img src="@/assets/logo.png" alt="">
            <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
            <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
                <el-menu-item index="1">首页</el-menu-item>
                <el-menu-item index="2">政策方案</el-menu-item>
                <el-menu-item index="3">新闻资讯</el-menu-item>
                <el-menu-item index="4">立即咨询</el-menu-item>
                <el-menu-item index="5">了解更多</el-menu-item>
                <el-menu-item index="6">注册申请</el-menu-item>
            </el-menu>
        </div>
        <div class="banner">
            <div class="box">
                <div class="box-content">
                    <div class="container" v-for="(item, index) in imgList" :key="index">
                        <img :src="item.url" alt="">
                        <p>{{ item.title }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="content">
            <el-divider style="margin-top: 40px;">特色专区</el-divider>
            <div class="product">
                <div class="left">
                    <div v-for="(item, index) in proList" :key="index"
                        :class="selectedIndex === index ? 'active' : 'left-list'">
                        <div
                            style=" display: flex;justify-content: flex-start; align-items: center;position: absolute; top: 50%;left: 50%;transform: translate(-50%, -50%);">
                            <img :src="selectedIndex === index ? item.iconActive : item.icon" alt="">
                            <p>{{ item.title }}</p>
                        </div>
                    </div>
                </div>
                <div class="center">
                    <img v-show="selectedIndex === 0" src="../assets/bcg.png" alt="">
                    <img v-show="selectedIndex === 1"
                        src="https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg" alt="">
                    <img v-show="selectedIndex === 2"
                        src="https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg" alt="">
                    <img v-show="selectedIndex === 3"
                        src="https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg" alt="">
                </div>
                <div class="txt">
                    <p v-show="selectedIndex === 0">{{ proList[0].text }}</p>
                    <p v-show="selectedIndex === 1">{{ proList[1].text }}</p>
                    <p v-show="selectedIndex === 2">{{ proList[2].text }}</p>
                    <p v-show="selectedIndex === 3">{{ proList[3].text }}</p>
                </div>
            </div>
            <el-divider style="margin-top: 40px;">APP详情</el-divider>
            <div class="app-img">
                <img src="../assets/app.png" alt="">
            </div>
        </div>
        <div class="content">
            <el-divider style="margin-top: 40px;">关于我们</el-divider>
        </div>
        <div class="about">
            <div class="about-content">
                <div class="about-txt">
                    <p>2017年3月，在省委省政府的统一安排下、哈工大与省农业农村厅签署《农业信息化建设战略合作框架协议》,《农业大数据合作协议》。</p>
                    <p>成立全国首个挂牌的省级农业大数据管理中心
                        启动我省农业大数据的建设和应用工作。同时，要求哈工大专业团队入驻省农业农村厅运营建设农业大数据管理中心。</p>
                </div>
            </div>
        </div>
        <div class="foot">
            <p>黑龙江农投大数据科技有限公司</p>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
    name: 'HomeView',
    components: {
        HelloWorld
    },
    data() {
        return {
            activeIndex: '1',
            activeIndex2: '1',
            selectedIndex: 0,
            imgList: [
                {
                    url: require('../assets/icon01.png'),
                    title: '优质企业'
                },
                {
                    url: require('../assets/icon02.png'),
                    title: '海量服务'
                },
                {
                    url: require('../assets/icon03.png'),
                    title: '资金保障'
                },
            ],
            proList: [
                {
                    icon: require('../assets/icon04.png'),
                    iconActive: require('../assets/icon04-active.png'),
                    title: '需求对接',
                    text: '裕农宝APP是为满足现有的农业农村市场及业务需求，由农投大数据基于土地、补贴、金融、政务等多维度数据打造的手机移动端应用。裕农宝APP是黑龙江农业生产托管服务平台的移动端门户入口，同时整合了线上下单、远程查看、在线验收、等特色功能，是构建农村“新金融”、打造农民“新农具”、形成农业“新业态”的主要使用工具。'
                },
                {
                    icon: require('../assets/icon05.png'),
                    iconActive: require('../assets/icon05-active.png'),
                    title: '服务管理',
                    text: '啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊'
                },
                {
                    icon: require('../assets/icon06.png'),
                    iconActive: require('../assets/icon06-active.png'),
                    title: '订单管理',
                    text: '哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈'
                },
                {
                    icon: require('../assets/icon07.png'),
                    iconActive: require('../assets/icon07-active.png'),
                    title: '远程监看',
                    text: '嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻嘻'
                },
            ]
        }
    },
    methods: {
        handleSelect(key, keyPath) {
            console.log(key, keyPath);
        },
        //切换特色专区
        toggle(index) {
            this.selectedIndex = index;
        },
        isActive(index) {
            return index
        }
    }
}
</script>
<style lang="less" scoped>
.nav {
    width: 1200px;
    margin: 20px auto;
    display: flex;
    justify-content: space-between;
}

.banner {
    position: relative;
    width: 100%;
    height: 489px;
    background-image: url('../assets/background.png');
    background-size: 100% 100%;

    .box {
        width: 870px;
        height: 102px;
        border-radius: 6px;
        background: #fff;
        position: absolute;
        top: 90%;
        left: 50%;
        transform: translate(-50%, 0);
        box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.123);

        .box-content {
            width: 80%;
            height: 100%;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;

            .container {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                color: #3D4A58;
            }
        }

    }
}

.content {
    width: 1200px;
    margin: 20px auto;

    .product {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 60px 0 0 0;

        .left {
            img {
                width: 20px;
                height: 20px;
            }

            p {
                margin-left: 10px;
            }
        }

        div:first-child {
            margin-top: 0;
        }

        .left-list {
            position: relative;
            width: 265px;
            height: 70px;
            margin-top: 35px;
            border-radius: 3px;
            background: #F8F9F9;
            cursor: pointer;
            color: #9D9A9A;
        }

        .active {
            position: relative;
            width: 265px;
            height: 70px;
            border-radius: 3px;
            margin: 10px 0;
            cursor: pointer;
            background-image: linear-gradient(to right, #19B0C1, #31C29B);
            color: #fff
        }

        .center {
            width: 654px;
            height: 383px;
            overflow: hidden;

            img {
                width: 100%;
            }
        }

        .txt {
            width: 250px;
            height: 384px;
            text-indent: 2em;
            line-height: 30px;
            text-align: left;
            background-color: #F8F8F8;

            p {
                margin: 20px;
            }
        }
    }

    .app-img {
        width: 100%;
        margin: 20px 0;

        img {
            width: 100%;
        }
    }
}

.about {
    position: relative;
    margin: 25px 0 0 0;
    width: 100%;
    height: 806px;
    background-image: url('../assets/bcg-foot.png');
    background-size: 100% 100%;

    .about-content {
        position: absolute;
        width: 1200px;
        height: 516px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-image: url('../assets/bcg-content.png');
        background-size: 100% 100%;
    }

    .about-txt {
        width: 326px;
        height: 380px;
        background: rgba(0, 0, 0, 0.308);
        position: absolute;
        top: 8%;
        left: 2%;
        padding: 20px 65px;
        text-align: left;
        line-height: 35px;
        color: #fff;
    }
}

.foot {
    width: 100%;
    height: 107px;
    display: flex;
    align-items: center;
    color: #fff;
    background: #2F3643;

    p {
        margin: 0 auto;
        font-size: 16px;
    }
}

.el-divider--horizontal {
    margin: 160px 0 0 0;

    .el-divider__text.is-center {
        color: #222B34;
        font-size: 28px;
    }

}

.el-menu.el-menu--horizontal {
    display: flex;
    align-items: center;
}

.el-menu--horizontal>.el-menu-item.is-active {
    background: rgba(57, 197, 148, 0.2);
    color: #39C595;
    border: none;
}

.el-menu--horizontal>.el-menu-item {
    border: none;
    height: 35px;
    line-height: 35px;
    font-weight: bold;
    color: #39424E;
}

.el-menu.el-menu--horizontal {
    border: none;
}</style>

